.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(33, 14, 14, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
.modal-content {
    position: relative;
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 90%;
    max-height: 80vh;
    overflow-y: auto;
    margin: 20px;
  }